import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import {
  changeEditMode,
  changeSelectedSigner,
} from "../../../../../app/annots/annotSlice";
import { openModal } from "../../../../../app/common/modals/modalSlice";
import DocPrepareSignerListItem from "./DocPrepareSignerListItem";

export default function DocPrepareSignerList() {
  const dispatch = useDispatch();
  const { signerListDisplay } = useSelector((state) => state.annot);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { editMode } = useSelector((state) => state.annot);

  function handleAddSigner() {
    dispatch(
      openModal({
        modalType: "DocPrepareAddSigner",
      })
    );
  }

  function handleYourFieldsClick() {
    dispatch(changeSelectedSigner(currentUserProfile));
    dispatch(changeEditMode("agent"));
  }

  const annotColor = "210, 210, 210";

  return (
    <>
      {" "}
      <div
        onClick={() => handleYourFieldsClick()}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Icon
          className="small right margin"
          name={
            editMode === "agent" ? "square check outline" : "square outline"
          }
          size="large"
        />
        <div style={{ width: "20px", height: "15px" }}>
          <div
            className="annot-outer-wrapper"
            style={{
              backgroundColor: `rgba(${annotColor}, 0.2)`,
            }}
          >
            <div
              className="annot-inner-wrapper"
              style={{
                boxShadow: `rgb(${annotColor}) 0px 0px 0px 1px`,
                zIndex: 5,
              }}
            ></div>
          </div>
        </div>
        <h5
          className="zero top margin small left margin"
          style={{ color: "#666" }}
        >
          Me:&nbsp;
          {currentUserProfile.firstName + " " + currentUserProfile.lastName}
        </h5>
      </div>
      <br />
      {signerListDisplay.map((client, index) => (
        <DocPrepareSignerListItem
          signer={client}
          key={client.role}
          index={index}
        />
      ))}
      <Button basic size="tiny" onClick={() => handleAddSigner()}>
        Add Signer
      </Button>
    </>
  );
}
