import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "semantic-ui-react";
import annotMakeResizable from "./annotMakeResizable";
import { createAnnotColor } from "./annotUtils";
import { format } from "date-fns";
import {
  selectDocAnnot,
  updateAnnot,
} from "../../../../../app/annots/annotSlice";
import { Rnd } from "react-rnd";

export default function AnnotDraggableField({ annot, pageScale }) {
  const dispatch = useDispatch();
  const { selectedAnnot } = useSelector((state) => state.annot);
  const { transaction } = useSelector((state) => state.transaction);
  const ref = useRef();
  const element = ref.current;

  const [annotPosition, setAnnotPosition] = useState({
    x: annot.x,
    y: annot.y,
  });
  const [annotPositionDisplay, setAnnotPositionDisplay] = useState({
    x: annot.x,
    y: annot.y,
  });

  useEffect(() => {
    const newAnnotPosition = {
      x: annotPosition.x * pageScale,
      y: annotPosition.y * pageScale,
    };
    setAnnotPositionDisplay(newAnnotPosition);
  }, [pageScale, annotPosition.x, annotPosition.y]);

  function handleOnStop(e, dragElement) {
    dispatch(
      updateAnnot({
        annot: annot,
        newFields: {
          x: dragElement.x / pageScale,
          y: dragElement.y / pageScale,
        },
      })
    );
    dispatch(selectDocAnnot(annot));
    setAnnotPosition({
      x: dragElement.x / pageScale,
      y: dragElement.y / pageScale,
    });
  }

  const color = annot.readOnly
    ? "232, 232, 232"
    : createAnnotColor(annot.signerRole, transaction);

  function convertTypeToPlaceholder(annotType) {
    switch (annotType) {
      case "signature":
        return "Signature";
      case "initials":
        return "Initials";
      case "date":
        return "mm/dd/yyyy";
      case "text":
        return "Sample text";
      case "checkbox":
        return "";
      default:
        return "";
    }
  }

  function handleResizeWidth(width, direction, offset) {
    let newAnnotFields = {};
    if (direction === "negative") {
      newAnnotFields["x"] = annot.x + offset / pageScale;
    }
    newAnnotFields["width"] = width / pageScale;
    dispatch(
      updateAnnot({
        annot: selectedAnnot,
        newFields: newAnnotFields,
      })
    );
    if (annot.type === "strikethrough") {
      let newLine = "";
      const numberOfLines = width / pageScale / 7.2;
      for (let i = 0; i < numberOfLines; i++) {
        newLine = newLine + "_";
      }
      dispatch(
        updateAnnot({
          annot: selectedAnnot,
          newFields: {
            text: newLine,
          },
        })
      );
    }
    return;
  }

  function handleResizeHeight(height, direction, offset) {
    let newAnnotFields = {};
    if (direction === "negative") {
      newAnnotFields["y"] = annot.y + offset / pageScale;
    }
    newAnnotFields["height"] = height / pageScale;
    dispatch(
      updateAnnot({
        annot: selectedAnnot,
        newFields: newAnnotFields,
      })
    );
    return;
  }

  if (element && selectedAnnot.uniqueId === annot.uniqueId) {
    annotMakeResizable(element, handleResizeWidth, handleResizeHeight, 6, 2);
  }
  return (
    <Rnd
      key={annot.id}
      width={annot.width * pageScale}
      height={annot.height * pageScale}
      bounds={`#canvas-${annot.page.toString()}`}
      cancel="#resize-top, #resize-bottom, #resize-left, #resize-right, #resize-nw, #resize-ne, #resize-sw, #resize-se"
      onDragStop={(e, dragElement) => handleOnStop(e, dragElement)}
      position={{
        x: annotPositionDisplay.x,
        y: annotPositionDisplay.y,
      }}
    >
      <div
        ref={ref}
        id={annot.uniqueId}
        style={{
          width: annot.width * pageScale,
          height: annot.height * pageScale,
          minWidth: 6 * pageScale,
          minHeight: 2 * pageScale,
          maxWidth: 960 * pageScale,
          maxHeight: 960 * pageScale,
          position: "absolute",
          display: "inline-block",
          cursor: "move",
          zIndex: 2,
          // Needed to align text properly
          lineHeight: 0.6,
          whiteSpace: "nowrap",
        }}
      >
        <div
          className="annot-outer-wrapper"
          style={{ backgroundColor: `rgba(${color}, 0.2)` }}
        >
          <div
            className="annot-inner-wrapper"
            style={{
              boxShadow: `rgb(${color}) 0px 0px 0px 1px`,
              zIndex: 5,
            }}
          >
            {annot.agentsField === false && (
              <span
                style={{
                  fontSize: `${annot.fontSize * pageScale}px`,
                  color: `rgb(${color})`,
                  fontFamily: `${annot.fontFamily}`,
                }}
              >
                {convertTypeToPlaceholder(annot.type)}
              </span>
            )}
            {annot.agentsField === false && annot.type === "checkbox" && (
              <Icon
                name="square outline"
                style={{
                  fontSize: `${annot.fontSize * pageScale}px`,
                  color: `${annot.fontColor}`,
                  textAlign: "left",
                  height: "unset",
                }}
              />
            )}
            {annot.agentsField === true && annot.type === "checkbox" && (
              <Icon
                name="check"
                style={{
                  fontSize: `${annot.fontSize * pageScale}px`,
                  color: `${annot.fontColor}`,
                  textAlign: "left",
                  height: "unset",
                }}
              />
            )}
            {annot.agentsField === true && annot.type !== "checkbox" && (
              <span
                style={{
                  fontSize: `${annot.fontSize * pageScale}px`,
                  color: `${annot.fontColor}`,
                  fontFamily: `${annot.fontFamily}`,
                }}
              >
                {annot.type === "date" && !annot.text ? (
                  format(new Date(), "MM/dd/yyyy")
                ) : (
                  <>{annot.text}</>
                )}
              </span>
            )}
          </div>
          {selectedAnnot.uniqueId === annot.uniqueId && (
            <div className="annot-position-0">
              <div
                className="annot-corner-circle annot-tr"
                style={{ borderColor: `rgb(${color})`, cursor: "nw-resize" }}
              />
              <div
                className="annot-corner-circle annot-tl"
                style={{ borderColor: `rgb(${color})`, cursor: "ne-resize" }}
              />
              <div
                className="annot-corner-circle annot-br"
                style={{ borderColor: `rgb(${color})`, cursor: "sw-resize" }}
              />
              <div
                className="annot-corner-circle annot-bl"
                style={{ borderColor: `rgb(${color})`, cursor: "se-resize" }}
              />
            </div>
          )}
        </div>
      </div>
    </Rnd>
  );
}
