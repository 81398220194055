import { app } from "../config/firebase";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";

const functions = getFunctions(app);

if (process.env.REACT_APP_USE_FUNCTIONS_EMULATOR === "true") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export function functionCreateNewUser(data) {
  return new Promise(async function (resolve, reject) {
    const createNewUser = httpsCallable(functions, "createNewUser");
    createNewUser(data)
      .then((result) => {
        const data = result.data;
        console.log("createNewUser: RETURNED DATA: ", result.data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionLinkUserToParty(data) {
  return new Promise(async function (resolve, reject) {
    const linkUserToParty = httpsCallable(functions, "linkUserToParty");
    linkUserToParty(data)
      .then((result) => {
        const data = result.data;
        console.log("LinkUserToParty: RETURNED DATA: ", result.data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionLinkTcUserToParty(data) {
  return new Promise(async function (resolve, reject) {
    const linkTcUserToParty = httpsCallable(functions, "linkTcUserToParty");
    linkTcUserToParty(data)
      .then((result) => {
        const data = result.data;
        console.log("LinkTcUserToParty: RETURNED DATA: ", result.data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionAddTcIdToTransaction(data) {
  return new Promise(async function (resolve, reject) {
    const addTcIdToTransaction = httpsCallable(
      functions,
      "addTcIdToTransaction"
    );
    addTcIdToTransaction(data)
      .then((result) => {
        const data = result.data;
        console.log("addTcIdToXact RETURNED DATA: ", result.data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionAddCoAgentIdToTransaction(data) {
  return new Promise(async function (resolve, reject) {
    const addCoAgentIdToTransaction = httpsCallable(
      functions,
      "addCoAgentIdToTransaction"
    );
    addCoAgentIdToTransaction(data)
      .then((result) => {
        const data = result.data;
        console.log("addCoAgentIdToXact RETURNED DATA: ", result.data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionRemoveTcIdFromTransaction(data) {
  return new Promise(async function (resolve, reject) {
    const removeTcIdFromTransaction = httpsCallable(
      functions,
      "removeTcIdFromTransaction"
    );
    removeTcIdFromTransaction(data)
      .then((result) => {
        const data = result.data;
        console.log("RETURNED DATA: ", result.data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionRemoveCoAgentIdFromTransaction(data) {
  return new Promise(async function (resolve, reject) {
    const removeCoAgentIdFromTransaction = httpsCallable(
      functions,
      "removeCoAgentIdFromTransaction"
    );
    removeCoAgentIdFromTransaction(data)
      .then((result) => {
        const data = result.data;
        console.log("RETURNED DATA: ", result.data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionShareWithAgent(data) {
  return new Promise(async function (resolve, reject) {
    const shareWithAgent = httpsCallable(functions, "shareWithAgent");
    console.log("BEFORE SEND: ", data);
    shareWithAgent(data)
      .then((result) => {
        const data = result.data;
        console.log("RETURNED DATA: ", result.data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionShareTaskWithAgent(data) {
  return new Promise(async function (resolve, reject) {
    const shareTaskWithAgent = httpsCallable(functions, "shareTaskWithAgent");
    console.log("TASK: ", data);
    shareTaskWithAgent(data)
      .then((result) => {
        const data = result.data;
        console.log("RETURNED DATA: ", result.data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionLinkTransactionWithAgent(data) {
  return new Promise(async function (resolve, reject) {
    const linkTransactionWithAgent = httpsCallable(
      functions,
      "linkTransactionWithAgent"
    );
    linkTransactionWithAgent(data)
      .then((result) => {
        const data = result.data;
        console.log("RETURNED DATA: ", result.data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionViewAuthClaimRole(data) {
  return new Promise(async function (resolve, reject) {
    const viewAuthClaimRole = httpsCallable(functions, "viewAuthClaimRole");
    viewAuthClaimRole(data)
      .then((result) => {
        const data = result.data;
        console.log("functionViewAuthClaimRole::", result.data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionAddAuthClaimRole(data) {
  return new Promise(async function (resolve, reject) {
    const addAuthClaimRole = httpsCallable(functions, "addAuthClaimRole");
    addAuthClaimRole(data)
      .then((result) => {
        const data = result.data;
        console.log("functionAddAuthClaimRole::", result.data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionAddAuthClaimAssistant(data) {
  return new Promise(async function (resolve, reject) {
    const addAuthClaimAssistant = httpsCallable(
      functions,
      "addAuthClaimAssistant"
    );
    addAuthClaimAssistant(data)
      .then((result) => {
        const data = result.data;
        console.log(result.data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionDelAuthClaimAssistant(data) {
  return new Promise(async function (resolve, reject) {
    const delAuthClaimAssistant = httpsCallable(
      functions,
      "delAuthClaimAssistant"
    );
    delAuthClaimAssistant(data)
      .then((result) => {
        const data = result.data;
        console.log(result.data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionViewAllUsersWithCustomClaims() {
  return new Promise(async function (resolve, reject) {
    const viewAllUsersWithCustomClaims = httpsCallable(
      functions,
      "viewAllUsersWithCustomClaims"
    );
    viewAllUsersWithCustomClaims()
      .then((result) => {
        const data = result.data;
        console.log(result.data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionImpersonateUser(data) {
  return new Promise(async function (resolve, reject) {
    const impersonateUser = httpsCallable(functions, "impersonateUser");
    impersonateUser(data)
      .then((result) => {
        const data = result.data.tokenResult;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionClientLogin(creds) {
  return new Promise(async function (resolve, reject) {
    const loginClient = httpsCallable(functions, "loginClient");
    loginClient(creds)
      .then((result) => {
        const data = result.data;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionPartyLogin(email) {
  return new Promise(async function (resolve, reject) {
    const partyLogin = httpsCallable(functions, "partyLogin");
    partyLogin(email)
      .then((result) => {
        const data = result.data;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionFetchLegalAddressData(address) {
  return new Promise(async function (resolve, reject) {
    const fetchLegalAddressData = httpsCallable(
      functions,
      "fetchLegalAddressData"
    );
    fetchLegalAddressData(address)
      .then((result) => {
        const data = result.data;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function functionFetchOwnerData(address) {
  return new Promise(async function (resolve, reject) {
    const fetchOwnerData = httpsCallable(functions, "fetchOwnerData");
    fetchOwnerData(address)
      .then((result) => {
        const data = result.data;
        if (data?.[0]?.firstName) {
          data[0].firstName = toTitleCase(data[0].firstName);
        }
        if (data?.[0]?.middleName) {
          data[0].middleName = toTitleCase(data[0].middleName);
        }
        if (data?.[0]?.lastName) {
          data[0].lastName = toTitleCase(data[0].lastName);
        }
        if (data?.[1]?.firstName) {
          data[1].firstName = toTitleCase(data[1].firstName);
        }
        if (data?.[1]?.middleName) {
          data[1].middleName = toTitleCase(data[1].middleName);
        }
        if (data?.[1]?.lastName) {
          data[1].lastName = toTitleCase(data[1].lastName);
        }

        if (data?.[0]?.entityName) {
          data[0].isTrust = true;
        } else {
          data[0].isTrust = false;
        }
        if (data?.[1]?.entityName) {
          data[1].isTrust = true;
        } else {
          data[1].isTrust = false;
        }

        resolve(data);
      })
      .catch((error) => {
        console.log("error fetchOwnerData:: ", error.message);
        reject(error);
      });
  });
}

export function functionFetchMlsIres(mlsId) {
  return new Promise(async function (resolve, reject) {
    const fetchMlsIres = httpsCallable(functions, "fetchMlsIres");
    fetchMlsIres(mlsId)
      .then((result) => {
        const data = result.data;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionFetchMlsDataFiniti(mlsId) {
  return new Promise(async function (resolve, reject) {
    const fetchMlsDataFiniti = httpsCallable(functions, "fetchMlsDataFiniti");
    fetchMlsDataFiniti(mlsId)
      .then((result) => {
        const data = result.data;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionFetchViewsFromDataFiniti() {
  return new Promise(async function (resolve, reject) {
    const fetchViewsFromDataFiniti = httpsCallable(
      functions,
      "fetchViewsFromDataFiniti"
    );
    fetchViewsFromDataFiniti()
      .then((result) => {
        const data = result.data;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionCreateViewInDataFiniti() {
  return new Promise(async function (resolve, reject) {
    const createViewInDataFiniti = httpsCallable(
      functions,
      "createViewInDataFiniti"
    );
    createViewInDataFiniti()
      .then((result) => {
        const data = result.data;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function functionGetDeadlinesFromPdf(docRef) {
  return new Promise(async function (resolve, reject) {
    const getDeadlinesFromPdf = httpsCallable(functions, "getDeadlinesFromPdf");
    getDeadlinesFromPdf(docRef)
      .then((result) => {
        const data = result.data;
        resolve(data);
      })
      .catch((error) => {
        throw error;
      });
  });
}

export function functionDeleteTransaction(transactionId) {
  return new Promise(async function (resolve, reject) {
    const deleteTransaction = httpsCallable(functions, "deleteTransaction");
    deleteTransaction(transactionId)
      .then((result) => {
        const data = result.data;
        resolve(data);
      })
      .catch((error) => {
        throw error;
      });
  });
}
