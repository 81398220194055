import React from "react";
import { Button, Grid, Input } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import TransactionUnderContractList from "./transactionUnderContract/TransactionUnderContractList";
import TransactionActiveListingList from "./transactionActiveListing/TransactionActiveListingList";
import TransactionActiveBuyerList from "./transactionActiveBuyer/TransactionActiveBuyerList";
import { Link, useParams } from "react-router-dom";
import { searchFilter } from "../../../app/common/util/util";
import { openModal } from "../../../app/common/modals/modalSlice";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useMediaQuery } from "react-responsive";

export default function TransactionActiveDashboard() {
  const dispatch = useDispatch();
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { transUnderContract, transActiveListing, transActiveBuyer } =
    useSelector((state) => state.transaction);
  const [searchTerms, setSearchTerms] = useState("");
  let { agentId } = useParams();
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  const transUnderContractFiltered = searchFilter(
    transUnderContract?.transactions,
    searchTerms
  );
  const transActiveListingFiltered = searchFilter(
    transActiveListing?.transactions,
    searchTerms
  );
  const transActiveBuyerFiltered = searchFilter(
    transActiveBuyer?.transactions,
    searchTerms
  );

  if (!(transUnderContract && transActiveListing && transActiveBuyer)) {
    return <LoadingComponent content="Loading transactions..." />;
  }

  return (
    <div className="main-page-wrapper">
      <Grid stackable>
        <Grid.Column computer={8}>
          <Input
            type="text"
            fluid
            size="small"
            placeholder="Search by client name or address"
            value={searchTerms}
            onChange={(e) => setSearchTerms(e.target.value)}
          ></Input>
        </Grid.Column>
        <Grid.Column computer={3} tablet={4}>
          <Button.Group fluid size="small">
            <Button active as={Link} to="">
              Active
            </Button>
            <Button as={Link} to={currentUserProfile?.role === "manager" ? `/agent/${agentId}/transactionsAll` : "/transactionsAll"}>
              All
            </Button>
          </Button.Group>
        </Grid.Column>
        <Grid.Column computer={5} tablet={4}>
          {(currentUserProfile.role === "agent" || currentUserProfile.role === "assistant") && (
            <Button
              color="blue"
              to="#"
              floated={isMobile ? null : "right"}
              icon="plus"
              size="small"
              className={isMobile ? "fluid" : null}
              onClick={() =>
                dispatch(
                  openModal({
                    modalType: "TransactionForm",
                  })
                )
              }
              content="New Transaction"
            />
          )}
        </Grid.Column>
        <Grid.Column computer={16}>
          <h3 className="mini bottom margin">Under Contract</h3>
          {transUnderContractFiltered?.length > 0 ? (
            <TransactionUnderContractList
              transactions={transUnderContractFiltered}
              column={transUnderContract.column}
              direction={transUnderContract.direction}
            />
          ) : (
            <p>There are no transactions under contract</p>
          )}

          <h3 className="mini bottom margin">Active Listings</h3>
          {transActiveListingFiltered?.length > 0 ? (
            <TransactionActiveListingList
              transactions={transActiveListingFiltered}
              column={transActiveListing.column}
              direction={transActiveListing.direction}
            />
          ) : (
            <p>There are no active listings</p>
          )}

          <h3 className="mini bottom margin">Active Buyers</h3>
          {transActiveBuyerFiltered?.length > 0 ? (
            <TransactionActiveBuyerList
              transactions={transActiveBuyerFiltered}
              column={transActiveBuyer.column}
              direction={transActiveBuyer.direction}
            />
          ) : (
            <p>There are no active buyers</p>
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
}
