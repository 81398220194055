import React, { useEffect, useState } from "react";
import { Formik, Form, useFormikContext } from "formik";
import {
  Button,
  Popup,
  Grid,
  Header,
  Label,
  Icon,
  Message,
} from "semantic-ui-react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
  addEmailToDb,
  sendTransactionEmail,
} from "../../app/firestore/firestoreService";
import MyTextInput from "../../app/common/form/MyTextInput";
import MyTextArea from "../../app/common/form/MyTextArea";
import {
  convertFullName,
  convertPartiesToSendEmail,
  convertRoleToSharingDisplay,
  generateEmailFields,
  partyIsAgent,
} from "../../app/common/util/util";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../app/common/modals/modalSlice";
import { clearEmailFromTemplate } from "./emailSlice";

export default function EmailDashboard() {
  const dispatch = useDispatch();
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { allParties, transaction } = useSelector((state) => state.transaction);
  const { emailFromTemplate } = useSelector((state) => state.email);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  let { id } = useParams();
  let email = emailFromTemplate;

  let initialValues = {
    subject: "",
    from: "",
    otherEmailsTo: "",
    otherEmailsCc: "",
    otherEmailsBcc: "",
    message: currentUserProfile?.emailSigField
      ? currentUserProfile.emailSigField
      : "",
  };

  const validationSchema = Yup.object({
    subject: Yup.string().required("You must provide a subject"),
  });

  const [toRoles, setToRoles] = useState([]);
  const [noToRolesError, setNoToRolesError] = useState(false);
  const [ccRoles, setCcRoles] = useState([]);
  const [bccRoles, setBccRoles] = useState([]);

  useEffect(() => {
    if (email.subject) {
      setToRoles(email.to);
      setCcRoles(email.cc);
      setBccRoles(email.bcc);
    }
  }, [email]);

  function Watcher() {
    const { values, setFieldValue } = useFormikContext();
    useEffect(() => {
      if (values.otherEmailsTo && noToRolesError) {
        setNoToRolesError(false);
      }
      if (email.subject) {
        setFieldValue("subject", email.subject);
        setFieldValue("from", email.from);
        setFieldValue("message", email.message);
        setFieldValue("otherEmailsTo", email.otherEmailsTo);
        setFieldValue("otherEmailsCc", email.otherEmailsCc);
        setFieldValue("otherEmailsBcc", email.otherEmailsBcc);
      }
    }, [setFieldValue, values.otherEmailsTo]);
    return null;
  }

  let agentProfile = {};
  if (transaction.agentProfile) {
    agentProfile = {
      email: transaction.agentProfile?.email || "",
      firstName: transaction.agentProfile?.firstName || "",
      lastName: transaction.agentProfile?.lastName || "",
      role: "Me (Agent)",
    };
  }

  let partiesAvailableToSend = convertPartiesToSendEmail(
    allParties,
    agentProfile,
    transaction.agentRepresents
  );

  function handleToClick(role) {
    if (toRoles && toRoles.includes(role)) {
      setToRoles(toRoles.filter((toRole) => toRole !== role));
    } else {
      setToRoles([...toRoles, role]);
      setNoToRolesError(false);
    }
  }

  function handleCcClick(role) {
    if (ccRoles && ccRoles.includes(role)) {
      setCcRoles(ccRoles.filter((ccRole) => ccRole !== role));
    } else {
      setCcRoles([...ccRoles, role]);
    }
  }

  function handleBccClick(role) {
    if (bccRoles && bccRoles.includes(role)) {
      setBccRoles(bccRoles.filter((bccRole) => bccRole !== role));
    } else {
      setBccRoles([...bccRoles, role]);
    }
  }

  function clearRecipientFields() {
    setToRoles([]);
    setCcRoles([]);
    setBccRoles([]);
  }

  function handleClear(resetForm) {
    setTimeout(() => {
      resetForm();
    }, 100);
    dispatch(clearEmailFromTemplate());
    clearRecipientFields();
  }

  return (
    <div className="secondary-page-wrapper" id="send-email-page">
      <Grid stackable>
        <Grid.Column computer={7}></Grid.Column>
        <Grid.Column computer={3}>
          <Button.Group fluid size="small">
            <Button active as={Link} to="">
              Send Email
            </Button>
            <Button as={Link} to={`/transactions/${id}/emailsHistory`}>
              Email History
            </Button>
          </Button.Group>
        </Grid.Column>
        <Grid.Column computer={6}>
          <Button
            color="blue"
            to="#"
            icon="plus"
            floated={isMobile ? "left" : "right"}
            size="small"
            className={isMobile ? "fluid" : null}
            onClick={() =>
              dispatch(
                openModal({
                  modalType: "EmailTemplatesSelect",
                })
              )
            }
            content={`Use Template`}
          />
        </Grid.Column>
        <Grid.Column computer={16}>
          <h3>
            Send Email
            <Popup
              flowing
              size="mini"
              position="top right"
              trigger={
                <Icon
                  name="info"
                  color="blue"
                  circular
                  inverted
                  size="mini"
                  style={{
                    marginLeft: "10px",
                    marginBottom: "3px",
                    paddingBottom: "7px",
                  }}
                />
              }
            >
              <p className="bold text-normal text blue mini bottom margin">
                Sending Emails
              </p>
              <p className="text-dark text-normal small bottom margin">
                Note: Only people in your 'Parties' page that have an email
                address are shown here.
                <br />
                Hover your mouse over a button to see a mini-pop-up reminder of
                who that person is and their email.
              </p>
              <p className="bold text-normal text blue mini bottom margin">
                How Email Works:
              </p>
              <span className="text-dark text-normal mini bottom margin">
                <ol>
                  <li>
                    {" "}
                    Click on the the buttons for each person you wish to send
                    this email to.
                    <br />
                    Their button will turn blue.{" "}
                    <Label
                      className="tiny right margin"
                      size="tiny"
                      color="blue"
                    >
                      Blue
                    </Label>{" "}
                  </li>
                  <li>
                    Optionally you also can type in email addresses in the
                    textfield.
                    <br />
                    You can add multiple email addresses by separating each with
                    a comma.
                  </li>
                  <li>Enter a Subject and Message in the text area boxes.</li>
                  <li>
                    Click the{" "}
                    <Label
                      className="tiny right margin"
                      size="tiny"
                      color="blue"
                    >
                      Send
                    </Label>{" "}
                    button in the lower-right.
                  </li>
                </ol>
              </span>
              <br />
              <p className="bold text-normal text blue mini bottom margin">
                How to Use Email Templates:
              </p>
              <p className="text-dark text-normal small bottom margin">
                You can create email templates by clicking on the drop-down by
                your name in the upper-right corner
                <br />
                and select the "Email" option under the "Templates" section.
                <br />
                To use a created email template:
                <ol>
                  <li>
                    Click on the{" "}
                    <Label
                      className="tiny right margin"
                      size="tiny"
                      color="blue"
                    >
                      + Use Template
                    </Label>{" "}
                    button.
                  </li>
                  <li>
                    Click the{" "}
                    <Label
                      className="tiny right margin"
                      size="tiny"
                      color="blue"
                    >
                      Choose
                    </Label>{" "}
                    button.
                    <br />
                    The template will auto-populate the recipients, subject, and
                    message.
                  </li>
                  <li>
                    Verify recipients, subject, and message then click the Send
                    button.
                  </li>
                </ol>
              </p>
            </Popup>
          </h3>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, { resetForm }) => {
              try {
                if (toRoles.length === 0 && !values.otherEmailsTo) {
                  setNoToRolesError(true);
                } else {
                  const emailFields = generateEmailFields(
                    values,
                    allParties,
                    agentProfile,
                    toRoles,
                    ccRoles,
                    bccRoles
                  );
                  await sendTransactionEmail(
                    values,
                    emailFields,
                    currentUserProfile,
                    transaction
                  );
                  await addEmailToDb(values, emailFields);

                  // setSubmitting(false);
                  handleClear(resetForm);
                  toast.success("Email successfully sent");
                }
              } catch (error) {
                toast.error(error.message);
                // setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, resetForm, dirty, isValid, values }) => (
              <Form className="ui form" autoComplete="off">
                <Watcher />
                <Grid className="zero bottom margin">
                  <Grid.Row className="small vertical padding">
                    <Grid.Column width={16} className="small bottom margin">
                      <Header
                        as="h5"
                        color="blue"
                        className="small bottom margin"
                      >
                        Recipients
                      </Header>
                      <div className="mini bottom margin">
                        <b>To:</b>{" "}
                        {partiesAvailableToSend.map((party) => (
                          <React.Fragment key={"to" + party.role}>
                            {party.email && (
                              <Popup
                                trigger={
                                  <Label
                                    key={party.role}
                                    style={{
                                      cursor: "pointer",
                                      marginBottom: "2px",
                                    }}
                                    color={
                                      toRoles?.includes(party.role)
                                        ? "blue"
                                        : null
                                    }
                                    onClick={() => handleToClick(party.role)}
                                  >
                                    {convertRoleToSharingDisplay(party.role)}
                                  </Label>
                                }
                              >
                                <p className="tiny bottom margin">
                                  <span className="bold text blue">
                                    {party.role}:
                                  </span>{" "}
                                  {convertFullName(party)}
                                  <br />
                                  {party.email}
                                </p>
                                {party.hasAssistant &&
                                  party.assistant?.firstName &&
                                  party.assistant?.lastName &&
                                  party.assistant?.email && (
                                    <p className="tiny bottom margin">
                                      <span className="bold text blue">
                                        Assistant:
                                      </span>{" "}
                                      {convertFullName(party.assistant)}
                                      <br />
                                      {party.assistant.email}
                                    </p>
                                  )}
                                {partyIsAgent(party.role) &&
                                  party.hasTransactionCoordinator &&
                                  party.transactionCoordinator?.firstName &&
                                  party.transactionCoordinator?.lastName &&
                                  party.transactionCoordinator?.email && (
                                    <p className="tiny bottom margin">
                                      <span className="bold text blue">
                                        TC:
                                      </span>{" "}
                                      {convertFullName(
                                        party.transactionCoordinator
                                      )}
                                      <br />
                                      {party.transactionCoordinator.email}
                                    </p>
                                  )}
                                {partyIsAgent(party.role) &&
                                  party.hasCoAgent &&
                                  party.coAgent?.firstName &&
                                  party.coAgent?.lastName &&
                                  party.coAgent?.email && (
                                    <p className="tiny bottom margin">
                                      <span className="bold text blue">
                                        CoAgent:
                                      </span>{" "}
                                      {convertFullName(party.coAgent)}
                                      <br />
                                      {party.coAgent.email}
                                    </p>
                                  )}
                              </Popup>
                            )}
                          </React.Fragment>
                        ))}
                        <div className="send-email-inputs">
                          <MyTextInput
                            name="otherEmailsTo"
                            size="mini"
                            placeholder="Other emails (comma separated)"
                            className="tiny left margin float-left"
                            style={{ height: "28px", width: "240px" }}
                          />
                        </div>
                      </div>
                      {noToRolesError && (
                        <Message className="text red">
                          At least one recipient required
                        </Message>
                      )}
                      <div className="mini bottom margin">
                        <b>cc:</b>{" "}
                        {partiesAvailableToSend.map((party) => (
                          <React.Fragment key={"cc" + party.role}>
                            {party.email && (
                              <Popup
                                trigger={
                                  <Label
                                    key={party.role}
                                    style={{
                                      cursor: "pointer",
                                      marginBottom: "2px",
                                    }}
                                    color={
                                      ccRoles?.includes(party.role)
                                        ? "blue"
                                        : null
                                    }
                                    onClick={() => handleCcClick(party.role)}
                                  >
                                    {convertRoleToSharingDisplay(party.role)}
                                  </Label>
                                }
                              >
                                <p className="tiny bottom margin">
                                  <span className="bold text blue">
                                    {party.role}:
                                  </span>{" "}
                                  {convertFullName(party)}
                                  <br />
                                  {party.email}
                                </p>
                                {party.hasAssistant &&
                                  party.assistant?.firstName &&
                                  party.assistant?.lastName &&
                                  party.assistant?.email && (
                                    <p className="tiny bottom margin">
                                      <span className="bold text blue">
                                        Assistant:
                                      </span>{" "}
                                      {convertFullName(party.assistant)}
                                      <br />
                                      {party.assistant.email}
                                    </p>
                                  )}
                                {partyIsAgent(party.role) &&
                                  party.hasTransactionCoordinator &&
                                  party.transactionCoordinator?.firstName &&
                                  party.transactionCoordinator?.lastName &&
                                  party.transactionCoordinator?.email && (
                                    <p className="tiny bottom margin">
                                      <span className="bold text blue">
                                        TC:
                                      </span>{" "}
                                      {convertFullName(
                                        party.transactionCoordinator
                                      )}
                                      <br />
                                      {party.transactionCoordinator.email}
                                    </p>
                                  )}
                                {partyIsAgent(party.role) &&
                                  party.hasCoAgent &&
                                  party.coAgent?.firstName &&
                                  party.coAgent?.lastName &&
                                  party.coAgent?.email && (
                                    <p className="tiny bottom margin">
                                      <span className="bold text blue">
                                        CoAgent:
                                      </span>{" "}
                                      {convertFullName(party.coAgent)}
                                      <br />
                                      {party.coAgent.email}
                                    </p>
                                  )}
                              </Popup>
                            )}
                          </React.Fragment>
                        ))}
                        <div className="send-email-inputs">
                          <MyTextInput
                            name="otherEmailsCc"
                            size="mini"
                            placeholder="Other emails (comma separated)"
                            className="tiny left margin"
                            style={{ height: "28px", width: "240px" }}
                          />
                        </div>
                      </div>
                      <div className="mini bottom margin">
                        <b>bcc:</b>{" "}
                        {partiesAvailableToSend.map((party) => (
                          <React.Fragment key={"bcc" + party.role}>
                            {party.email && (
                              <Popup
                                trigger={
                                  <Label
                                    key={party.role}
                                    style={{
                                      cursor: "pointer",
                                      marginBottom: "2px",
                                    }}
                                    color={
                                      bccRoles?.includes(party.role)
                                        ? "blue"
                                        : null
                                    }
                                    onClick={() => handleBccClick(party.role)}
                                  >
                                    {convertRoleToSharingDisplay(party.role)}
                                  </Label>
                                }
                              >
                                <p className="tiny bottom margin">
                                  <span className="bold text blue">
                                    {party.role}:
                                  </span>{" "}
                                  {convertFullName(party)}
                                  <br />
                                  {party.email}
                                </p>

                                {party.hasAssistant &&
                                  party.assistant?.firstName &&
                                  party.assistant?.lastName &&
                                  party.assistant?.email && (
                                    <p className="tiny bottom margin">
                                      <span className="bold text blue">
                                        Assistant:
                                      </span>{" "}
                                      {convertFullName(party.assistant)}
                                      <br />
                                      {party.assistant.email}
                                    </p>
                                  )}
                                {partyIsAgent(party.role) &&
                                  party.hasTransactionCoordinator &&
                                  party.transactionCoordinator?.firstName &&
                                  party.transactionCoordinator?.lastName &&
                                  party.transactionCoordinator?.email && (
                                    <p className="tiny bottom margin">
                                      <span className="bold text blue">
                                        TC:
                                      </span>{" "}
                                      {convertFullName(
                                        party.transactionCoordinator
                                      )}
                                      <br />
                                      {party.transactionCoordinator.email}
                                    </p>
                                  )}
                                {partyIsAgent(party.role) &&
                                  party.hasCoAgent &&
                                  party.coAgent?.firstName &&
                                  party.coAgent?.lastName &&
                                  party.coAgent?.email && (
                                    <p className="tiny bottom margin">
                                      <span className="bold text blue">
                                        CoAgent:
                                      </span>{" "}
                                      {convertFullName(party.coAgent)}
                                      <br />
                                      {party.coAgent.email}
                                    </p>
                                  )}
                              </Popup>
                            )}
                          </React.Fragment>
                        ))}
                        <div className="send-email-inputs">
                          <MyTextInput
                            name="otherEmailsBcc"
                            size="mini"
                            placeholder="Other emails (comma separated)"
                            className="tiny left margin"
                            style={{ height: "28px", width: "240px" }}
                          />
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <Header
                        as="h5"
                        color="blue"
                        className="mini bottom margin"
                      >
                        Subject
                      </Header>
                      <MyTextInput
                        name="subject"
                        className="small bottom margin"
                      />
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <Header
                        as="h5"
                        color="blue"
                        className="mini bottom margin"
                      >
                        Message
                      </Header>
                      <MyTextArea
                        name="message"
                        className="small bottom margin"
                        rows="12"
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  floated={isMobile ? null : "right"}
                  primary
                  content="Send"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
                <Button
                  disabled={isSubmitting}
                  to="#"
                  type="button"
                  floated={isMobile ? null : "right"}
                  content="Clear"
                  className={isMobile ? "fluid medium bottom margin" : null}
                  onClick={() => handleClear(resetForm)}
                />
              </Form>
            )}
          </Formik>
        </Grid.Column>
      </Grid>
    </div>
  );
}
